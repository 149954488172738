//import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';

//var endpointUrl = "http://localhost:5000";
var endpointUrl = "";

const headers = () => {
   const h = new Headers();
   h.append('Content-Type', 'application/json');

   const session = {
       token: localStorage.getItem('token')
   }

   if(session.token !== "null") {
       h.append('X-Token', session.token);
   }

   return h;
};

const request = (method, path, body) => {
   const url = endpointUrl + path;
   const options = { method, headers: headers() };

   if(body) {
      options.body = JSON.stringify(body);
   }

   return fetch(new Request(url, options));
};

const Api = {
   get(path) {
    return request('GET', path);
  },
   post(path, body) {
    return request('POST', path, body);
  },
   put(path, body) {
    return request('PUT', path, body);
  }
}

export default Api;

