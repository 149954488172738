import React from 'react';

import CollapsibleUserTable from './CollapsibleUserTable';

class User extends React.Component {
  //constructor(props) {
  //   super(props);
  //}

  componentDidMount() {
  }

  render() { return ( <div> <CollapsibleUserTable/> </div>); }
}


export default User;

