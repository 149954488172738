import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { v4 as uuidv4 } from 'uuid';
import Checkbox from '@material-ui/core/Checkbox';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '@material-ui/core/Button';

import LabelledOutline from './LabelledOutline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Api from './Api';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function redirect_to_jupyter() {
    var old_token = localStorage.getItem("token");
    if(old_token == null) {
        return (<div>Nothing here...</div>);
    }

    var url = "https://bl832viz5x.als.lbl.gov:8443/?token=" + old_token;
    return (window.open("https://bl832viz5x.als.lbl.gov:8443/?token=" + old_token));
}
    
function call_link(row, event, beamline, view_only) {
   //console.log("event", row);

   if(row.link.indexOf("Jupyter") >= 0)
   {
       redirect_to_jupyter();
       return;
   }

   var token = localStorage.getItem("token");
   var url = "/api/get/create_link?resource=" + encodeURIComponent(row.link) + "&event=" + encodeURIComponent(event) + "&beamline=" + encodeURIComponent(beamline);
   Api.get(url).then(res => res.json()).then(data => {
      //console.log(data);
      url = "https://remote.als.lbl.gov/guacamole/?token=" + data.link + "&event=" + encodeURIComponent(event) + "&beamline=" + encodeURIComponent(beamline) + "&resource=" + encodeURIComponent(row.link) + "&auth=" + token + "&readonly=" + view_only.toString() + "&nonce=" + uuidv4();
      //var newWindow = window.open(); newWindow.opener = null; newWindow.location = url; 
      window.open(url);
   });
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.link}
        </TableCell>
        <TableCell align="center">{row.beamline}</TableCell>
        <TableCell align="center">{row.link !== "Loading" ? <Checkbox checked={row.view_only} color="primary" disabled disableRipple/> : ''}</TableCell>
        <TableCell align="center">{row.scheduled_event.substring(0, row.scheduled_event.indexOf("["))}</TableCell>
        <TableCell align="center">{row.resource.substring(0, row.resource.indexOf("["))}</TableCell>
        <TableCell align="center">{row.start}</TableCell>
        <TableCell align="center">{row.stop}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.links.map((historyRow) => (
                    <TableRow key={uuidv4()}>
                      <TableCell component="th" scope="row">
                        <Link component="button" variant="body2" onClick={() => {call_link(historyRow, row.scheduled_event, row.beamline, row.view_only)}} >
                            Active Link: {historyRow.link.substring(0, historyRow.link.indexOf("["))}
                         </Link>
                      </TableCell>
                      <TableCell>Description: {historyRow.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
    row: PropTypes.shape({
      link : PropTypes.string.isRequired,
      beamline : PropTypes.string.isRequired,
      view_only : PropTypes.bool.isRequired,
      scheduled_event: PropTypes.string.isRequired,
      resource: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      stop: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
         PropTypes.shape({
           link: PropTypes.string.isRequired,
           description: PropTypes.string.isRequired,
         }),
      ).isRequired,
    }).isRequired,
};

class CollapsibleUserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows : [ {link : 'Loading', beamline : '(Please Wait..) ', view_only : false, scheduled_event : '-', resource : '-', start : '-', stop : '-', links : []}]
        };

        this.load_table = this.load_table.bind(this);
    }
    
    redirect_to_jupyter() {
        var old_token = localStorage.getItem("token");
        if(old_token == null) {
           return (<div>Nothing here...</div>);
        }

        var url = "https://bl832viz5x.als.lbl.gov:8443/?token=" + old_token;
        return (window.open("https://bl832viz5x.als.lbl.gov:8443/?token=" + old_token));
    }
    
    load_table() {

        Api.get("/api/get/user_events").then(res => res.json()).then(data => {
            var rows = [];

            data = data["Events"];

            for(var i = 0; i < data.length; ++i) {
                var links = [];

                for(var j = 0; j < data[i].links.length; ++j) {
                    links.push({
                             link: data[i].links[j].name,
                             description: data[i].links[j].description,  
                    });
                }

                if(data[i].beamline === "8.3.2") {
                    links.push({
                             link: "Jupyter []",
                             description:  "Access Jupyter Resources",
                    });
                }

                var row = {link : '', beamline: data[i].beamline, view_only : data[i].view_only, scheduled_event : data[i].title, resource : data[i].resource, start: new Date(data[i].start).toDateString(), stop: new Date(data[i].end).toDateString(), links: links};
                rows.push(row);
            }


            //console.log('adding', rows);
            this.setState({rows: rows});
        });
    }

    componentDidMount() {
          this.load_table();
          //(async () => {
          //     this.load_table();
          // })();
    }

    render() {
      return (
        <div>
        <Grid container item spacing={3} xs={12} style={{ minWidth : 800, overflow : "auto" }}>
          <Grid item xs={12}>
            <LabelledOutline label="Instructions">
              <Typography>  
                ALS-RAC is currently under normal operations. If you would like to schedule access please contact your beamline staff representative.<br/>
                Please join our ALS Slack channel: 
                <Link href="https://join.slack.com/t/advanced-light-source/shared_invite/zt-vg57kzow-a8TL9okdcNzsHnvkLmF5vg" color="primary"> ALS Slack Link
                </Link> Channel: #remote-access-issues <br/>
                Coming Soon: Frequently Asked Questions<br/>
                   Get Help: alsrac@lbl.gov<br/>
                   Once you are connected to a remote computer, to activate/deactivate settings: <b>Ctrl+Alt+Shift</b>
              </Typography>
            </LabelledOutline>
           </Grid>
           <Grid item xs={12} alignItems="center">
                <Button variant="contained" color="primary" onClick={this.load_table}> Refresh Page (If no events present) </Button>
           </Grid>
        
           <Grid item xs={12}>
             <TableContainer >
               <Table aria-label="collapsible table">
                   <TableHead>
                       <TableRow>
                           <TableCell />
                           <TableCell></TableCell>
                           <TableCell align="center">Beamline</TableCell>
                           <TableCell align="center">View Only</TableCell>
                           <TableCell align="center">Scheduled Event</TableCell>
                           <TableCell align="center">Resource</TableCell>
                           <TableCell align="center">Start</TableCell>
                           <TableCell align="center">Stop</TableCell>
                       </TableRow>
                   </TableHead>
                   <TableBody>
                    {this.state.rows.map((row) => (
                        <Row key={uuidv4()} row={row} />
                    ))}
                   </TableBody>
               </Table>
              </TableContainer>
           </Grid>
        </Grid>
        </div>
      );
  }
}

export default CollapsibleUserTable;
