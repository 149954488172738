import React from 'react';
//import clsx from 'clsx';
import PropTypes from 'prop-types';
//import { makeStyles } from '@material-ui/core/styles';
//import FormControl from '@material-ui/core/FormControl';
//import Tabs from '@material-ui/core/Tabs';
//import Tab from '@material-ui/core/Tab';
//import PhoneIcon from '@material-ui/icons/Phone';
//import FavoriteIcon from '@material-ui/icons/Favorite';
//import PersonPinIcon from '@material-ui/icons/PersonPin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
//import AppBar from '@material-ui/core/AppBar';
//import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
//import { DateTimePicker } from '@material-ui/pickers'
//import StaticTimePicker from '@material-ui/lab/StaticTimePicker';
//import KeyboardTimePicker from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
//import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
//import IconButton from '@material-ui/core/IconButton';
//import CommentIcon from '@material-ui/icons/Comment';
//import { DateRangePickerComponent } from "materialui-daterange-picker";
import Autocomplete from '@material-ui/core/Autocomplete';
import AsyncAutocomplete from './AsyncAutocomplete';
import Dnd from './BeamlineCalendar';
//import * as AdapterMoment from '@material-ui/lab/AdapterMoment';
import MomentUtils from '@date-io/moment';
import { StaticDateRangePicker, LocalizationProvider } from '@material-ui/lab';
import moment from 'moment';
//import * as AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@material-ui/icons/Edit';
import { alpha } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
//import OutlinedInput from '@material-ui/core/Input';
//import InputLabel from '@material-ui/core/InputLabel';
//import InputAdornment from '@material-ui/core/InputAdornment';

//import Visibility from '@material-ui/icons/Visibility';
//import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LabelledOutline from './LabelledOutline';
import Api from './Api';

// eslint-disable-next-line
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

// eslint-disable-next-line
Date.prototype.addHours = function(hours) {
    var date = new Date(this.valueOf());
    date.setHours(date.getHours() + hours);
    return date;
}

// eslint-disable-next-line
Date.prototype.toUTC = function() {
var now_utc =  Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate(),
 this.getUTCHours(), this.getUTCMinutes(), this.getUTCSeconds());
 return now_utc;
}

class StaticDateRangePickerX extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          time1: new Date(),
          time2: new Date(),
          value : [new Date(), new Date().addDays(1)]
      };

      this.date_range = this.date_range.bind(this);
      this.set_date_range = this.set_date_range.bind(this);
  }

  date_range() {
     

     try {
     if(this.state.value === null) 
         return null;

     var value = this.state.value.slice();
     if(value.length === 2 && value[1] === undefined) {
        //set both to be the same date
        value[1] = value[0].toDate();
        value[0] = value[0].toDate();
     } else if(value.length === 1) {
        var nd = value[0].toDate();
        value[0] = value[0].toDate();
        value.push(nd);
     } else {
         value[0] = value[0].toDate();
         value[1] = value[1].toDate();
     }

     value[0].setHours(this.state.time1.getHours(), this.state.time1.getMinutes(), 0);
     value[1].setHours(this.state.time2.getHours(), this.state.time2.getMinutes(), 0);

     //fail if time is not set
     if(value[1] <= value[2]) 
         return null;

     console.log(value);
     }
     catch(error) {
         //if error set one day before now to one day after now 
         value = [ Date.now().addDays(-1) , Date.now().addDays(1) ];
     }
     return value;
  }

  set_date_range(start, end) {
      this.setState({value: [start, end]});
  }

  render() {
        const {...other} = this.props;

    const changeDate = (e) => {
         console.log("Change Date" + e);
         if(e === null || e === "Invalid Date")
             return;
         this.setState({"time1" : e});
    };

    const changeDate2 = (e) => {
         console.log("Change Date" + e);
         if(e === null || e === "Invalid Date")
             return;

         this.setState({"time2" : e});
    };
    return (
    <Grid container>
      <Grid item xs={7}>
    <LocalizationProvider dateAdapter={MomentUtils}>
      <StaticDateRangePicker
        {...other}
        displayStaticWrapperAs="desktop"
        value={this.state.value}
        onChange={(newValue) => {
          this.setState({ value : newValue });
        }}

        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} variant="standard" />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} variant="standard" />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
    </Grid>
    <Grid container item xs={5}>

    <Grid item xs={12}>
     <MuiPickersUtilsProvider utils={DateFnsUtils}>
       <KeyboardTimePicker
          margin="normal"
          id="time-picker-1"
          label="Start Time"
          value={this.state.time1}
          onChange={changeDate}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
     </MuiPickersUtilsProvider>
    </Grid>

    <Grid item xs={12}>
     <MuiPickersUtilsProvider utils={DateFnsUtils}>
       <KeyboardTimePicker
          margin="normal"
          id="time-picker-2"
          label="End Time"
          value={this.state.time2}
          onChange={changeDate2}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
     </MuiPickersUtilsProvider>
    </Grid>

    </Grid>
    </Grid>
    );
  }
}

class CheckboxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked : [0],
            user_lists : [],
        };

        this.checked = this.checked.bind(this);
        this.add_entry = this.add_entry.bind(this);
        this.exists = this.exists.bind(this);
        this.user_list = this.user_list.bind(this);
        this.remove_selected = this.remove_selected.bind(this);
    }
    exists(content) {
        return this.state.user_lists.indexOf(content) >= 0;
    }

    checked() {
        return this.state.checked;
    }

    add_entry(content) {
        if(content.length === 0) return;
        if(this.exists(content)) return;
        this.setState({ user_lists : [...this.state.user_lists, content] });
    }

    user_list() {
        return this.state.user_lists;
    }

    remove_selected() {        

        var new_user_lists = []
        for(var i = 0; i < this.state.user_lists.length; ++i) {
             var index = this.state.checked.indexOf(this.state.user_lists[i]);
             if(index === -1) {
                 new_user_lists.push(this.state.user_lists[i]);
             }
        }

        this.setState({checked : [0]});
        this.setState({ user_lists : new_user_lists });
    }

    render() {
        const handleToggle = (value) => () => {
            const currentIndex = this.state.checked.indexOf(value);
            const newChecked = [...this.state.checked];

            if (currentIndex === -1) {
              newChecked.push(value);
            } else {
              newChecked.splice(currentIndex, 1);
            }

            //setChecked(newChecked);
            this.setState({checked : newChecked});
        };

        const {...other} = this.props;

        return (
        <List {...other}>
        {this.state.user_lists.map((value) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
          <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox edge="start" tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value} />
          </ListItem>
          );
        })}
        </List>);
    }
}

/*
const useHistoryStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: 800,
    maxHeight: 800,
    border: 'ridge',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));
*/

class HistoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked : [0],
            resources : []
        };
        this.checked = this.checked.bind(this);
        this.remove_selected = this.remove_selected.bind(this);
        this.add_entry = this.add_entry.bind(this);
        this.resources = this.resources.bind(this);
        //this.click_handler = this.click_handler.bind(this);
        this.clear = this.clear.bind(this);
    }
    
    clear() {
        this.setState({resources : [], checked : [0] });
    }

    click_handler(event, value) {
        var content = event.target.textContent;
        var search = content.indexOf(" (");
        var result = content.substring(0, search).trim();
        var output = null;
        for(var i = 0; i < this.state.resources.length; ++i) {
              if(result === this.state.resources[i].title) {
                  output = this.state.resources[i];
              }
        }
        this.props.click_callback(output["other"]);
    }

    exists(title) {
        for(var i = 0; i < this.state.resources.length; ++i) {
             if(title === this.state.resources[i].title) {
                 return true;
             }
        }
        return false;
    }

    checked() {
        return this.state.checked;
    }

    resources() {
        return this.state.resources;
    }

    add_entry(content) {
        if(this.exists(content.title))
            return false;

        var new_out = [...this.state.resources]
        for(var i = 0; i < new_out.length; ++i) {
             new_out[i]["index"] = i;
        }
        new_out.push({...content, index : this.state.resources.length});
        this.setState({ resources : new_out });
        return true;
    }

    remove_selected() {
        var new_user_lists = []
        for(var i = 0; i < this.state.resources.length; ++i) {
             var index = this.state.checked.indexOf(this.state.resources[i].name);
             if(index === -1) {
                 new_user_lists.push(this.state.resources[i]);
             }
        }

        this.setState({checked : [0]});
        this.setState({ resources : new_user_lists });
    }

    //const classes = useHistoryStyles();

    render() {
      const {...other} = this.props;

      const handleToggle = (value) => () => {
          const currentIndex = this.state.checked.indexOf(value);
          const newChecked = [...this.state.checked];

          if (currentIndex === -1) {
              newChecked.push(value);
          } else {
              newChecked.splice(currentIndex, 1);
          }

          this.setState({ checked : newChecked });
      };

      return (
    <List {...other}>
      {this.state.resources.map((value) => {
        const labelId = `checkbox-list-label-${value}`;

        return (
          <ListItem key={value.title} role={undefined} dense button onClick={handleToggle(value.title)}>
            <ListItemIcon>
              <Checkbox edge="start" tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.title + " (" + value.start.toString() + " to " + value.end.toString() + ")"} />
          </ListItem>
        );
      })}
    </List>
  );
  }
}

/*
const useAdorStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
  },
}));

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
*/
async function als_fetch_callback(prefix) {
     const response = await Api.get("/api/get/users_by_search?prefix="+prefix);
     let user_list = await response.json();
     var users = [
     ];

     //console.log(user_list);

     user_list = user_list["People"];
     if(user_list === undefined) {
        return users;
     }

     for(var i = 0; i < user_list.length; ++i) {
         users.push({ name : user_list[i].LastName + ", " + user_list[i].FirstName + " (" + user_list[i].OrgEmail + ")", "email" : user_list[i].OrgEmail});
     }
     return users
}

async function esaf_fetch_callback(beamline) {

     var esafs_output = []
     const response = await Api.get("/api/get/esaf_by_beamline_data?beamline=" + beamline);
     const esafs = await response.json();
     var today = new Date().addDays(-10);

     var esafs_map = {}

     //esafs.sort(function(a,b) {
     //     return a.ProposalFriendlyId < b.ProposalFriendlyId;
     //});

     for(var i = 0; i < esafs.length; ++i) {
        var se = esafs[i].ScheduledEvents;

        //se.sort(function(a,b) {
        //    return Date.parse(a.StartDate) < Date.parse(b.StartDate);
       // });

        for(var j = 0; j < se.length; ++j) {
             var start = se[j].StartDate + " " + se[j].StartTime;
             var end = se[j].EndDate + " " + se[j].EndTime;
             var start_date = Date.parse(start);
             //var end_date = Date.parse(end);

             if(start_date >= today) {
             //if(true) {
                 
                 var key = esafs[i].ProposalFriendlyId + " Exp: " + esafs[i].ExpLead.Name + " PI: " + esafs[i].PI.Name + " (" + start + " to " + end + ") - [" + j + "]";
                 //let key = esafs[i].ProposalFriendlyId + "(" + start + " to " + end + ")";
                 if(key in esafs_map) {
                    //if new esaf id is higher then replace
                    if(esafs[i].EsafId > esafs_map[key].esaf_id) {
                        esafs_map[key] = {name: key,  esaf_id: esafs[i].EsafId, original: esafs[i] };
                    }
                 } else {
                        esafs_map[key] = {name: key,  esaf_id: esafs[i].EsafId, original: esafs[i] };
                 }
             }
         }
     }

     esafs_output = Object.values(esafs_map);
     //console.log(esafs_output);
     return esafs_output;
};

class ScheduleUsers extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          open : true,
          view_only : false,
          dateRange : {},
          esafData : "",
          beamline_option_selected : false,
      };

      this._checkboxlist = React.createRef();
      this._dnd = React.createRef();
      this._history_list = React.createRef();
      this._select_beamline_scheduler = React.createRef();

      this.add_als_user = this.add_als_user.bind(this);
      this.add_esaf = this.add_esaf.bind(this);
      this.remove_selection = this.remove_selection.bind(this);
      this.schedule_user = this.schedule_user.bind(this);
      this.resource_callback = this.resource_callback.bind(this);
      this.update_date_range = this.update_date_range.bind(this);

      this.esaf_output = {};
      this.esaf_callback = this.esaf_callback.bind(this);
      this.als_callback = this.als_callback.bind(this);
      this.als_prefix = "+";
      this.als_results = {};
      this.als_pending = {};

      this._select_als_users = React.createRef();
      this._select_esaf = React.createRef();

      this.modes = ["events you created", "beamline"]; //TODO: hide and show

      this.get_manager_events = this.get_manager_events.bind(this);
      this.get_manager_events_by_beamline = this.get_manager_events_by_beamline.bind(this);

      this.date_range_picker = React.createRef();
  }

  async get_manager_events() {
        this._dnd.current.clear();
        this._history_list.current.clear();

        var resp = await Api.get("/api/get/manager_events");
        var data = await resp.json();
            //console.log("rows", data);
        for(var i = 0; i < data.Events.length; ++i) {
               var event = {
                   "beamline" : data.Events[i].beamline,
                   "title": data.Events[i].title,
                   "resource": data.Events[i].resource,
                   "start":  new Date(data.Events[i].start),
                   "end": new Date(data.Events[i].end), //add a day so it spans end date inclusive
                   "user_list" : data.Events[i].user_list
               };

               this._dnd.current.add_calendar_event(event);
               this._history_list.current.add_entry(event);
        }
  }

  async get_manager_events_by_beamline(beamline) {
        this._dnd.current.clear();
        this._history_list.current.clear();

        var resp = await Api.get("/api/get/manager_events_by_beamline?beamline=" + beamline);
        var data = await resp.json();

        for(var i = 0; i < data.Events.length; ++i) {
               var event = {
                   "beamline" : data.Events[i].beamline,
                   "title": data.Events[i].title,
                   "resource": data.Events[i].resource,
                   "start":  new Date(data.Events[i].start),
                   "end": new Date(data.Events[i].end), //add a day so it spans end date inclusive
                   "user_list" : data.Events[i].user_list
               };

               this._dnd.current.add_calendar_event(event);
               this._history_list.current.add_entry(event);
        }
  }

  componentDidMount() {
      console.log("calling now");
      this.get_manager_events();

      for(var i = 0; i < this.props.beamlines.length; ++i) {
          var beamline = this.props.beamlines[i];
          if(!(beamline in this.esaf_output)) {
              this.esaf_output[beamline] = esaf_fetch_callback(beamline);
          }
      }
      //(async () => {
      //         await this.get_manager_events();
      // })();
  }

  async esaf_callback(source) {
        var beamline = document.getElementById("select-beamline").value;
        if(beamline in this.esaf_output) {
            return this.esaf_output[beamline];
        }
        this.esaf_output[beamline] = await esaf_fetch_callback(beamline);
        return this.esaf_output[beamline];
  }

  async als_callback(content) {
        //if the values start with the same character do not perform
        //a second search

        var cv = document.getElementById("select-als-users").value;

        if(cv === undefined || cv.length === 0) {
            cv = "A";
        }

       if(cv[0] in this.als_results) return this.als_results[cv[0]]; //if previously in pending
       if(cv[0] in this.als_pending) return null;

       this.als_pending[cv[0]] = cv[0]; //add
       this.als_results[cv[0]] = await als_fetch_callback(cv[0]);

       var result = null;
       if (Object.keys(this.als_pending).length <= 1) //if last pending item then return it
           result = this.als_results[cv[0]];
       delete this.als_pending[cv[0]]; //remove
       return result;
  }

  add_als_user() {
     var value = document.getElementById("select-als-users").value;
     this._checkboxlist.current.add_entry(value);
     this._select_als_users.current.clear_selection();
     this.update_date_range(value);
  }

  add_esaf() {
     var value = document.getElementById("select-esaf").value;
     this._checkboxlist.current.add_entry(value);
     this._select_esaf.current.clear_selection();
     this.update_date_range(value);
  }

  update_date_range(value) {
      var resources = this._checkboxlist.current.user_list();
      var event_title = document.getElementById("select-title");

      event_title.value = "";

      for(var i = 0; i < resources.length; ++i) {
           if(resources[i].startsWith("ALS") || value.startsWith("BLS")) {
               var range = resources[i].substring(resources[i].indexOf("(") + 1, resources[i].indexOf(")"));
               var split_range = range.split(" to ");
               this.date_range_picker.current.set_date_range(moment(new Date(split_range[0])), moment(new Date(split_range[1])));
               document.getElementById("select-title").value = resources[i];
               return;
           }
      }

      if(value.startsWith("ALS") || value.startsWith("BLS")) {
               var arange = value.substring(value.indexOf("(") + 1, value.indexOf(")"));
               var a_split_range = arange.split(" to ");
               this.date_range_picker.current.set_date_range(moment(new Date(a_split_range[0])), moment(new Date(a_split_range[1])));
               document.getElementById("select-title").value = value;
               return;
      } 

      //this.date_range_picker.current.set_date_range(moment(new Date().addHours(-1)), moment((new Date()).addDays(1).addHours(8)));
      var tday = new Date();
      this.date_range_picker.current.set_date_range(moment(new Date(tday.getFullYear(), tday.getMonth(), tday.getDate(), 0, 0, 0)), moment((new Date(tday.getFullYear(), tday.getMonth(), tday.getDate(), 23, 59, 59)).addDays(1)));
      document.getElementById("select-title").value = value;
  }

  remove_selection() {
     this._checkboxlist.current.remove_selected();
  }

  schedule_user() {
     var title = document.getElementById("select-title").value;
     var beamline = document.getElementById("select-beamline").value;
     var user_list = this._checkboxlist.current.user_list()
     var resource = document.getElementById("select-resources").value
     //console.log(beamline, user_list, this.state.dateRange, resource);

     if(title.length === 0) { alert("No title provided"); return; }
     if(user_list.length === 0) { alert("No list of users provided"); return; }
     if(beamline.length === 0) { alert("No beamline selected"); return; }
     if(resource.length === 0) { alert("No resources provided"); return; }

     var date_range = this.date_range_picker.current.date_range();
     
     if(date_range.length === null) {
         alert("Date range is correct. Please reselect"); return;
     }

     title = title + " [" + uuidv4() + "]";

     //var range_1 = date_range[0].toDate();
     //var range_2 = date_range[1].toDate();

     //var r1 = new Date(range_1.getFullYear(), range_1.getMonth(), range_1.getDate(), 0, 0, 0);
     //var r2 = new Date(range_2.getFullYear(), range_2.getMonth(), range_2.getDate(), 23, 59, 59);
     //
     var r1 = date_range[0];
     var r2 = date_range[1];

     var utc_1 = r1.toUTC();
     var utc_2 = r2.toUTC();

     var contents = {
         beamline: beamline,
         user_list: user_list,
         //start: date_range[0].toDate(),
         //end: date_range[1].toDate(),
         start: r1,
         end: r2,
         resource: resource,
         title: title
     }

     var post_data = {
         beamline: beamline,
         user_list: user_list,
         //start: date_range[0].toDate().toUTC(),
         //end: date_range[1].toDate().toUTC(),
         start: utc_1,
         end: utc_2,
         resource: resource,
         view_only : this.state.view_only,
         title: title
     }

     document.getElementById("select-title").value = "";
     //console.log(JSON.stringify(contents));
     var post = Api.post("/api/post/event", post_data);
     //var result = post.json();
     //var response = post.json();
     //console.log(response);
     console.log(post);
     this._dnd.current.add_calendar_event(contents);
     this._history_list.current.add_entry(contents);
  }

  async resource_callback() {
      var element = document.getElementById("select-beamline").value;
      var response = await Api.get("/api/get/collections_by_beamline?beamline=" + element);
      var data = await response.json();

      let results = []
      for(var i = 0; i < data.results.length; ++i) {
         results.push({
              index : i,
              name : data.results[i].name,
         });
      }
      return results;
  }

  render() {
    //const toggle = () => this.setState({open : this.state.open});
    const remove_event = () => { 
        console.log("remove event called");

        var rem_resources = []
        var new_resources = []
        var checked = this._history_list.current.checked();
        var resources = this._history_list.current.resources();

        for(var j = 0; j < resources.length; ++j) {
           var matched = false;

           for(var k = 0; k < checked.length; ++k) {

              if(resources[j].title === checked[k]) {
                  rem_resources.push(resources[j]);
                  matched = true;
                  break;
              }
           }
           if(matched === false) {
               new_resources.push(resources[j]);
           }
        }
        
        if(rem_resources.length > 0) {
            var beamline = rem_resources[0].beamline;
            var titles = []

            console.log("REMOVING", rem_resources);

            for(var i = 0; i < rem_resources.length; ++i) {
                titles.push(rem_resources[i].title);
            }
            var post_data = {
                beamline : beamline,
                titles : titles
            };

            var post = Api.post("/api/post/remove_events", post_data);
            console.log(post, post_data);
        }
        
        var value = document.getElementById("select-mode").value;
        console.log("MODE:", value);
        //mode_changed(null, value);
        this.get_manager_events();

/*
        console.log("NEW RESOURCES", new_resources);
        new Promise(() => {
            this._dnd.current.clear();
            this._history_list.current.clear();
        }).then(() => {

        console.log("PROMISE NEW RESOURCES", new_resources);
        for(var i = 0; i < new_resources.length; ++i) {
               var event = {
                   "beamline" : new_resources[i].beamline,
                   "title": new_resources[i].title,
                   "resource": new_resources[i].resource,
                   "start":  new Date(new_resources[i].start),
                   "end": new Date(new_resources[i].end), //add a day so it spans end date inclusive
                   "user_list" : new_resources[i].user_list
               };

               console.log("ADDING", event);

               this._dnd.current.add_calendar_event(event);
               this._history_list.current.add_entry(event);
        }
        });
     */
    };

    const mode_changed = (_, value) => { 
        if(value === "events you created") {
           //(async () => {
           //    await this.get_manager_events();
           //})();
           this.setState({beamline_option_selected : false});
           this.get_manager_events();
        }
        else {
          this.setState({ beamline_option_selected : true});
          this.get_manager_events_by_beamline(this.props.beamlines[0]);

           //new Promise(resolve => this.setState({ beamline_option_selected : true})).then(() => {
           //    var beamline = document.getElementById("select-beamline-scheduler").value;
           //    this.get_manager_events_by_beamline(this.props.beamlines[0]);
           //});
           //(async () => {
           //    var beamline = document.getElementById("select-beamline-scheduler").value;
           //    await this.get_manager_events_by_beamline(beamline);
           //})();
        }
    };

    const beamline_changed = (_, value) => { 
        var mode = document.getElementById("select-mode").value;
        console.log("BEAMLINE MODE", mode, value);
        if(mode === "beamline") {
            this.get_manager_events_by_beamline(value);
        }
    };

     const handleChange = (event) => {
        this.setState({ view_only : event.target.checked });
     };

    return (
    <div>
     <Grid container item spacing={3} xs={12} style={{ maxWidth : 1200, overflow : "auto" }}>
          <Grid item xs={12}>
                <Autocomplete id="select-beamline" 
                  style={{ width : "200" }}
                  disableClearable
                  label="Select Beamline" 
                  defaultValue={this.props.beamlines[0]}
                  options={this.props.beamlines}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (<TextField {...params} label="Beamline" variant="outlined" /> )}
                />
          </Grid>
          <Grid item xs={12}>
              <LabelledOutline label="Select Individuals/Groups to Grant Access">
               <Grid container spacing={3}>
                <Grid container item xs={7} spacing={2}>
                 <Grid container item xs={8} alignItems="stretch" direction="column">
                     <AsyncAutocomplete ref={this._select_esaf} id="select-esaf" label="ESAF..." fetch_callback={this.esaf_callback}/>
                 </Grid>
                 <Grid container item xs={4}>
                   <Button fullWidth variant="contained" color="primary" onClick={this.add_esaf}> Add </Button>
                 </Grid>
                 <Grid container item xs={12}>
                  <Typography>--OR--</Typography>
                 </Grid>
                 <Grid container item xs={8} alignItems="stretch" direction="column">
                     <AsyncAutocomplete style={{ width : '100%' }} ref={this._select_als_users} id="select-als-users" label="Users..." fetch_callback={this.als_callback} />
                 </Grid>
                 <Grid container item xs={4}>
                     <Button fullWidth variant="contained" color="primary" onClick={this.add_als_user}> Add </Button>
                 </Grid>
                </Grid>
                <Grid container item xs={5} alignItems="stretch" direction="column">
                   <LabelledOutline label="User/ESAF">
                       <CheckboxList ref={this._checkboxlist} style = {{ height : 100, overflow : "auto" }} id="checkboxlist"/>
                       <Button fullWidth variant="contained" color="primary" onClick={this.remove_selection}> Remove Selection </Button>
                   </LabelledOutline>
                </Grid>
               </Grid>
              </LabelledOutline>
          </Grid>

        <Grid container item xs={12} alignItems="center" direction="column">
            <Box border={1}>
                 <StaticDateRangePickerX ref={this.date_range_picker}/>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <TextField
                id="select-title"
                label="Event Title"
                style={{ margin: 8 }}
                placeholder="Title of the Event"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                variant="outlined"
            />
        </Grid>
        <Grid container item xs={8} alignItems="stretch" direction="column">
                <AsyncAutocomplete id="select-resources" 
                  label="Grant Access to Resource/Collection" 
                  fetch_callback={this.resource_callback}
                />
        </Grid>
        <Grid container item xs={4} alignItems="stretch" direction="row">
        <FormControlLabel
        control={
          <Checkbox
            checked={this.state.view_only}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="View Only"
        />
            <Button fullWidth variant="contained" color="primary" onClick={this.schedule_user}> Schedule User </Button>
        </Grid>

        <Grid item xs={12} >
          <Divider/>
        </Grid>

        <Grid container item xs={12}>
            <Typography variant="h4"> Beamline Scheduler </Typography>
            <Divider/>
        </Grid>

        <Grid item xs={6} >
                <Autocomplete id="select-mode" 
                  disableClearable
                  label="Select Mode" 
                  defaultValue={this.modes[0]}
                  options={this.modes}
                  onChange={mode_changed}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (<TextField {...params} label="Filter By" variant="outlined" /> )}
                />
        </Grid>
        <Grid item xs={6}>
                {this.state.beamline_option_selected ?
                <Autocomplete id="select-beamline-scheduler" 
                  disableClearable
                  label="Select Beamline Schedule" 
                  defaultValue={this.props.beamlines[0]}
                  options={this.props.beamlines}
                  onChange={beamline_changed}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (<TextField {...params} label="Beamline" variant="outlined" /> )}
                /> : ''
                }
        </Grid>
        <Grid item xs={12} >
            <LabelledOutline label="Remote Scheduler">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Dnd style={{ height : 500, overflow : "auto" }} ref={this._dnd}/>
                </Grid>
                <Grid item xs={4}>
                    <LabelledOutline label="Scheduled Events">
                        <HistoryList style={{ height : 400, overflow : "auto" }} ref={this._history_list}/>
                    <Button fullWidth variant="contained" color="primary" onClick={remove_event}> Remove Selected Event </Button>
                    </LabelledOutline>
                </Grid>
              </Grid>
            </LabelledOutline>
        </Grid>
      </Grid>
    </div>);
  }
}

ScheduleUsers.propTypes = {
  beamlines: PropTypes.arrayOf(
         PropTypes.string.isRequired,
         ),
};

export default ScheduleUsers;

